
import './Title.css';
import {  useNavigate  } from 'react-router-dom';
import { FaShoppingCart } from 'react-icons/fa';
import { AppContext } from '../../context';
import { PageHeader, Button, Descriptions } from 'antd';
import React, {useEffect, useState,useContext} from 'react';
import { IoLogoOctocat } from 'react-icons/io';
import { BButton, BCirc } from '../BeiTec';
import { MdArrowBackIosNew } from 'react-icons/md';
import logo from '../../assets/logo.png';

export default function Title({voltar=false, children, titulo="", name, sub}){
  const navigate  = useNavigate();
  const { signIn, loading, setUser, user, menuAberto, setMenuAberto } = useContext(AppContext);

  const [isMobile, setIsMobile] = useState(false);
  const [hwidth, setHwidth] = useState(window.innerWidth);
  const [showUserBox, setShowUserBox] = useState(false);
  


  useEffect(() => {
   function handleResize() {
       setHwidth(window.innerWidth);
   }
   window.addEventListener("resize", handleResize);
   return () => window.removeEventListener("resize", handleResize);
 }, [hwidth]);

 useEffect(() => {
  if( hwidth < 901) { 
     if( !isMobile){ setIsMobile(true) }   }else{ if(isMobile){ setIsMobile(false) }
    }
   
   }, [hwidth]);

    return(<>
    { showUserBox &&
    <div className="menu-user-fundo" onClick={ ()=> { setShowUserBox(false) }}>
      <div className="menu-user-box">
        <div className="menu-user-infos">
        <BButton texto={"Meu Perfil"} width={"100px"} onClick={ () => { navigate("/") }}/>
        <BButton texto={"Meus Pedidos"} width={"100px"} onClick={ () => { navigate("/") }}/>
        </div>
        <div className="menu-user-btns">
        
          <BButton texto={"Saír"} width={"100px"} onClick={ () => { navigate("/") }}/>
        </div>
      </div> 
    </div>}


    <div className="title-title">
      <div className="title-dados" style={{gap:"15px"}}>
      <img src={logo} style={{cursor:"pointer"}} onClick={ () => {  window.location = "https://falangeproducoes.com.br/";   }} ></img>  
        <div className="title-dados-voltar">
          { voltar && <BButton fundo="rgba(255, 255, 255, 0)" borda={false} padding={"0"} texto="Voltar" txTipo={"hint"}
                          onClick={()=>{navigate(-1)}} icon={<MdArrowBackIosNew size={26} color={"white"} />} /> } 
        </div> 
        { (name && !isMobile) && <p>{name}</p> }   
      </div>

      <div className="title-cart">
        <FaShoppingCart size={26} color={"white"} />
        0,00
      </div>

      <a className="title-title-user" onClick={ () => {setShowUserBox(!showUserBox) }} >{user} <IoLogoOctocat size={28} /></a>     
    </div> 
 
    </>
    )
}