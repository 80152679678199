import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { toast } from "react-toastify";

export const AppContext = createContext({});


const ContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);   

    const [ menuAberto, setMenuAberto] = useState(false);   
   
    const [user, setUser] = useState(localStorage.getItem("loja_user"));  
    
    const [ ctx_enc_status, set_ctx_enc_status ] = useState([]);
    const [ ctx_cad_grupos, set_ctx_cad_grupos ] = useState([]);

    const [ ctx_fretes, set_ctx_fretes ] = useState([]);

    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);   
    const [hwidth, setHwidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setHwidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [hwidth]);
  
    useEffect(() => {
        let temp_hwidth = hwidth;
        if( temp_hwidth < 620) { 
            if( !isMobile ){ setIsMobile(true) }   
            if( isTablet ){ setIsTablet(false) }  
        }else{ 
            if(isMobile){ setIsMobile(false) }           
            if( temp_hwidth < 890) {
                if(!isTablet){ setIsTablet(true) }
            }else{
                if( isTablet ){ setIsTablet(false) }  
            }
        }
       // toast.success(temp_hwidth)
    }, [hwidth]);
    
    // retorna o que esta dentro do app.jsx
    return (
        <AppContext.Provider value={{signed: !!user, loading, 
            user, setUser, menuAberto, setMenuAberto, 
            ctx_enc_status, set_ctx_enc_status,
            ctx_cad_grupos, set_ctx_cad_grupos,
            ctx_fretes, set_ctx_fretes,
            isTablet, isMobile, hwidth, 
        }}>
             {children}
        </AppContext.Provider>

    )
}

export default ContextProvider;

