import React, {useEffect, useState,useContext} from 'react';
import './Main.css';
import { AppContext } from '../../context';
import { toast } from 'react-toastify';
import api from '../../services/api';

import NavBar from '../../components/NavBar/NavBar'

import Title from '../../components/Title/Title'
import { Row, Col, Button } from 'antd';
import { BsNewspaper } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { BAppContent } from '../../components/BeiTec';
import { asyncGet } from '../../services/utils';
import Produtos from '../Produtos/Produtos';
import Produto from '../Produto/Produto';

export default function Main({children, name}){
    const { signIn, loading, setUser, user } = useContext(AppContext);

    const [linkMaps,setLinkMaps] = useState("");
    const [codProd,setCodProd] = useState("");

    const clientIp = JSON.stringify( Object.values(require("os").networkInterfaces()).flat());

    const itemNews = (data, hora, titulo, infos) =>{
        return(<> 
                <div className="dash_news_fundo">
                            <div className="dash_news_data">
                            {data} {hora}
                            </div> 
                            <div className="dash_news_item">
                                <div className="dash_news_img">
                                    <BsNewspaper size={35}/>
                                </div>   
                                <div className="dash_news_infos">
                                    <div className="dash_news_title">
                                        {titulo}
                                    </div>   
                                    <div className="dash_news_body">
                                        {infos}
                                    </div>  
                                    <div className="dash_news_bottom">
                                    </div>                              
                                </div>   
                            </div>   
                        </div> 
        
        </>)
    }

    const itemInfos = (titulo, qtd, valor) => { 
        return(<>
                        <div className="dash_infos_item_fundo">                         
                            <div className="dash_infos_item">                  
                                <div className="dash_infos_titulo">
                                    {titulo}
                                </div>  
                                <div className="dash_infos_qtd">
                                    {qtd}
                                </div> 
                                <div className="dash_infos_valor">
                                   R$ {valor}
                                </div> 
                            </div>  
                        </div>  
        </>)
    }



function getLocation()
  {
  if (navigator.geolocation)
    {
    navigator.geolocation.getCurrentPosition(showPosition,showError);
    }
  else{
      alert("Geolocalização não é suportada nesse browser.");
    }
  }

function showPosition(position)
  {
    let lat=position.coords.latitude;
    let lon=position.coords.longitude;

    alert(JSON.stringify(position.coords.latitude))
    let latlon = position.coords.latitude + "," + position.coords.longitude;

    setLinkMaps("https://www.google.com.br/maps/place/@"+String(lat)+","+String(lon)+",16.25z");
  

  }




function clickTest(){
 // asyncGet("funcTest", {id: 2, }, (e) => { toast.success(">> "+JSON.stringify(e)); })
} 

function showError(error)
  {
  switch(error.code)
    {
    case error.PERMISSION_DENIED:
        alert("Usuário rejeitou a solicitação de Geolocalização.");
      break;
    case error.POSITION_UNAVAILABLE:
        alert("Localização indisponível.");
      break;
    case error.TIMEOUT:
        alert("O tempo da requisição expirou.");
      break;
    case error.UNKNOWN_ERROR:
        alert("Algum erro desconhecido aconteceu.");
      break;
    }
  }


  function isEmpty(object) {
    for (const property in object) {
      return false;
    }
    return true;
  }


    return(
    <>
        <BAppContent titulo={`Dashboard`} sub={""}>
        <div className="dash_fundo">
          <div className="dash_menu_topo">
          
          </div>  
          <div className="dash_content">
            {codProd.length > 3 ? <>
              {/* PAG DO PRODUTO*/}
              <Produto value={codProd} setValue={setCodProd}/>
            </> : <>
              {/* PAG DOS PRODUTOS*/}
              <Produtos setValue={setCodProd}/>
            </>}
            
          </div>   
        </div>   
        </BAppContent>
 
    </>
    )
}