import './Seletor.scss'
import React, {useEffect, useState,useContext} from 'react';
import { AppContext } from '../../context';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import { FiHome,FiShoppingCart,FiLayers,FiHexagon,FiUsers,FiBookOpen,FiFramer,FiAperture,FiDollarSign,
    FiList,FiClock,FiPercent,FiActivity,FiTrendingUp,FiTruck } from 'react-icons/fi';
import avatar from '../../assets/avatar.png';
import Modal from '../../components/Modal/Modal';
import ModalCad from '../../components/ModalCad/ModalCad';
import { asyncGet } from '../../services/utils';


const SeletorCad = ({setShow = () => {}, resSelected = () => {}, tipo="cadastros"}) => {
    const { signIn, loading, setUser, user } = useContext(AppContext);

    const [showModal, setShowModal] = useState(true);

    const [ showModalCadastro, setShowModalCadastro ] = useState(false);
    const [idPessoaSelecionada, setIdPessoaSelecionada ] = useState(-1);

    const [txBusca, setTxBusca] = useState("");
    const [ itens, setItens ] = useState([]);

    const [novoCad, setNovoCad ] = useState();

    function busca(tx){
        let vlr = tx;
        setItens([]);
        if(vlr.length >= 3){
            
            let resp = [];
            if(tipo == "cadastros"){
                setItens([]);
                asyncGet("cadastroBusca", { busca: vlr, tipos: '' }, (e)=>{  
                        setItens(e.cads);
                    }, false);
            }
            if(tipo == "users"){
                //alert("a");
                let v_dir = "";
                let v_obj = {};
                if(vlr == "ALL"){
                    v_dir = "getUsers";
                    v_obj = {local: true};
                }else{
                    v_dir = "buscaUsers";
                    v_obj = {busca: vlr};
                }
                 asyncGet(v_dir, v_obj, (e)=>{ setItens(e.users); }, false);
            }            
        }
    }



    useEffect( () => {
      
        resSelected({});
        if(tipo == "users"){
            
            busca("ALL");
        }

    },[]);

    useEffect( () => {
      if(novoCad != undefined && novoCad != null && novoCad){
          if(novoCad.nome.length > 3){
            busca(novoCad.nome);
          }
   
      }
       

    },[novoCad]);

    useEffect(() => { 
        if(txBusca.length >= 3){
            busca(txBusca);
        }
     },[txBusca]);

    return(
        <>

            <Modal  titulo="Busque e selecione!" 
                    click_close={ () => { setShow(false);} } >
                         
                { showModalCadastro && <ModalCad idSel={idPessoaSelecionada} 
                     setShow={ () => { setShowModalCadastro(false); setIdPessoaSelecionada(-1); }} 
                     onSave={  (e) => { setNovoCad(e) }}
                     
                     
                    /> 
                
                }
          
                <div className="row">
                    <div className="fundo_seletor">
                        <div className="seletor_top">
                            <div className="cx-box-cod-input">
                                <input className="input-cx-busca" autoFocus type="text" value={txBusca} placeholder="Busque aqui!"
                                onChange={ (e) => { setTxBusca(e.target.value) }  } />
                                { tipo == "cadastros" ? <>
                                <button className="btn-cx-busca" onClick={ () => {  } }>OK</button>
                                <button className="btn-cx-busca" onClick={ () => {setShowModalCadastro(true) } }>NOVO</button>
                                </> : <></>}
                            </div>
                        </div>
                        <div className="seletor_lista">
                            <ul>
                                { itens.map( (ite) => { return(
                                <li className="seletor_lista_item" onClick={ () => { resSelected(ite);  setShow(false); } }>
                                   <p>{ite.nome}</p> <p>{ite.apelido}</p> <p>{ite.cidade}</p> 
                                </li>
                                ) } ) }
                                
    
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal> 
            
        </>
    )

}

export default SeletorCad;