import axios from 'axios';
import { toast } from 'react-toastify';
import {  useNavigate  } from 'react-router-dom';

//const hostApi= 'https://api1.beitec.com.br';
//const hostApi = 'http://200.203.17.247:5000/api_1';
const hostApi = 'http://192.168.1.10:5111/';

const api = axios.create(
    {
        baseURL: hostApi,
        validateStatus: function (status) {         
           if(status === 401 || status === 422 || status === 500 ){
                toast.warn(String(status)+" - Logar novamente!");
                localStorage.setItem("loja_tk","");
                window.location = "/login";
            }
           if(status === 205){
               toast.error('Erro. ');
               return status;         
           }       
           return status >= 200 && status < 300      
        },
    }
)
export default api;

export const apiFile = axios.create(
    {
        baseURL: hostApi,
        validateStatus: function (status) {         
           if(status === 401 || status === 422 || status === 500 ){
              //  toast.warn(String(status)+" - Logar novamente!");
              //  localStorage.setItem("loja_tk","");
               // window.location = "/login";
            }
           if(status === 205){
              // toast.error('Erro. ');
             //  return status;         
           }       
           return status >= 200 && status < 300      
        },
    }
)