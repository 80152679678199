import React, {useEffect, useState} from 'react';
import api from './services/api';
import { BrowserRouter, Routes, Route, Navigate  } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute'
import Main from './pages/Main/Main'
import Login from './pages/Login/Login'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';

function App() {
  const [ resp, setResp] = useState([]);

  useEffect( () => {

  }, [] );


  const defName = "";

// <GlobalStyle/>
    //res = await action.envia("", "login", new UnaLogin() { email = unaEmail, senha = unaSenha, disp = "PC_TESTE" });
  return (  
          <BrowserRouter>
            <ToastContainer autoClose={2000} />
            <Routes> 
       
                <Route exact path={defName} element={<Main/>}/>
   
            </Routes>
          </BrowserRouter>

  );
}

export default App;
