import './Produtos.scss'
import React, {useEffect, useState,useContext} from 'react';
import { AppContext } from '../../context';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import avatar from '../../assets/avatar.png';
import Modal from '../../components/Modal/Modal';
import { BNum, BValor, BEdit, BCombo2 ,BComboOp,BCirc, BCheck } from '../../components/BeiTec';
import { safeInt, safeStr } from '../../services/utils';
import { SearchOutlined, LikeOutlined  } from '@ant-design/icons';
import { Table, Input, Button, Transfer , Select, Space, Row, Col, Spin  } from 'antd';
import SeletorCad from '../../components/Seletor/Seletor';
import prod_a from '../../assets/prod_a.png';
import prod_b from '../../assets/prod_b.png';
import prod_c from '../../assets/prod_c.png';
import prod_d from '../../assets/prod_d.png';

const Produtos = ({setValue = () => {},}) => {
 
    const [carregando, setCarregando] = useState(false);
    
    useEffect( () => {
       
      }, []);

    return(
        <div className='prods_fundo'>
    
            <div className='prods_item_box' onClick={() => { setValue("99544112101") }} >
                <div className='prods_item_capa'>
                   <img src={prod_a} onClick={ () => {  }} ></img>  
                </div>
                <div className='prods_item_titulo'>
                Falange Gaúcha
                </div>
                <div className='prods_item_infos'>
                O presídio Central e a história do crime organizado no RS
                </div>
                <div className='prods_item_valores'>
                    <div style={{fontSize:"20px",}}>❤️📘🎁</div>
                    <div style={{}}>
                        <div style={{textAlign:"end",fontSize:"15px", textDecoration: "line-through", fontFamily:"fantasy"}}>29,90</div>
                        <div style={{fontSize:"20px", fontWeight:"500", fontFamily:"fantasy"}}>69,90</div>
                    </div>
                </div>
            </div>

            <div className='prods_item_box' onClick={() => { setValue("99544112101") }} >
                <div className='prods_item_capa'>
                   <img src={prod_b} onClick={ () => {  }} ></img>  
                </div>
                <div className='prods_item_titulo'>
                Paz nas prisões Guerra nas ruas
                </div>
                <div className='prods_item_infos'>
                Grande Reportagem de Renato Dornelles e Tatiana Sager. Com depoimentos de cerca de 150 fontes,
                </div>
                <div className='prods_item_valores'>
                    <div style={{fontSize:"20px",}}>❤️📘🎁</div>
                    <div style={{}}>
                        <div style={{textAlign:"end",fontSize:"15px", textDecoration: "line-through", fontFamily:"fantasy"}}>29,90</div>
                        <div style={{fontSize:"20px", fontWeight:"500", fontFamily:"fantasy"}}>69,90</div>
                    </div>
                </div>
            </div>

            

            <div className='prods_item_box' onClick={() => { setValue("99544112101") }} >
                <div className='prods_item_capa'>
                   <img src={prod_d} onClick={ () => {  }} ></img>  
                </div>
                <div className='prods_item_titulo'>
                    A Cor da Esperança
                </div>
                <div className='prods_item_infos'>
                Com prefácio do escritor e ensaísta, Luiz Augusto Fischer, o primeiro livro ficcional de Renato Dornelles é uma homenagem à Restinga Velha e resgata, através da história, territórios que foram núcleos negros na
                </div>
                <div className='prods_item_valores'>
                    <div style={{fontSize:"20px",}}>❤️📘🎁</div>
                    <div style={{}}>
                        <div style={{textAlign:"end",fontSize:"15px", textDecoration: "line-through", fontFamily:"fantasy"}}>29,90</div>
                        <div style={{fontSize:"20px", fontWeight:"500", fontFamily:"fantasy"}}>69,90</div>
                    </div>
                </div>
            </div>

            <div className='prods_item_box' onClick={() => { setValue("99544112101") }} >
                <div className='prods_item_capa'>
                   <img src={prod_c} onClick={ () => {  }} ></img>  
                </div>
                <div className='prods_item_titulo'>
                Retratos do Cárcere – 1a temporada
                </div>
                <div className='prods_item_infos'>
                2020. Série documental, 13 episódios. 14 anos. Dos mesmos criadores de ‘Central – O poder das facções no maior presídio do Brasil’: Renato Dornelles e Tatiana Sager.
                </div>
                <div className='prods_item_valores'>
                    <div style={{fontSize:"20px",}}>📽️🍿</div>
                    <div style={{}}>
                        <div style={{textAlign:"end",fontSize:"15px", textDecoration: "line-through", fontFamily:"fantasy"}}>29,90</div>
                        <div style={{fontSize:"20px", fontWeight:"500", fontFamily:"fantasy"}}>69,90</div>
                    </div>
                </div>
            </div>
           
        </div>
    )

}

export default Produtos;