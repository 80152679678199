import './style.scss'
import React, {useEffect, useState,useContext, useRef, Children, useCallback} from 'react';
import { AppContext } from '../../context';
//import { toast } from 'react-toastify';
//import api from '../../services/api';
import { FiSearch,FiEdit2, FiPackage, FiMoreHorizontal,FiDisc } from 'react-icons/fi';
import { VscJson } from 'react-icons/vsc';



import { apenasNumeros, asyncGet, delay, floatToReal, focusComponent, handleEnter, isObjectEmpty, realToFloat, safeInt, safeStr, updStateJson, updStateJsonOne } from '../../services/utils';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { InputNumber, DatePicker, Spin } from 'antd';

import { Tag, Button, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import EmojiPicker from 'emoji-picker-react';

import { VscOpenPreview, VscFilter, VscSearch } from "react-icons/vsc";

import { format } from 'path';
import { toast } from 'react-toastify';

import { Table, Input, Tooltip  } from 'antd';
import { SearchOutlined, WhatsAppOutlined, MailOutlined, DeleteOutlined, DeleteFilled, LikeOutlined, LikeFilled ,
        CaretUpOutlined , CaretDownOutlined   } from '@ant-design/icons';
import { Alert } from 'antd';
import NavBar from '../NavBar/NavBar';
import Title from '../Title/Title';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';

import {
    Menu,
    Item,
    Separator,
    Submenu,
    useContextMenu
  } from "react-contexify";
  
  import "react-contexify/dist/ReactContexify.css";
import { randomInt } from 'crypto';
import SelCliente from '../SelCliente/SelCliente';
import { isMobile } from 'react-device-detect';

const { Option } = Select;


  export const BNum = ({reef,className, minWidth="78px", name, comBtn=!isMobile, titulo, height, hint, value=0, disabled, def, cor, modelo=1, max=1, min=1, setValue,onExit,onEnter ,next, width="100px"}) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);
    // DEF = valor default
    const he = titulo ? height ? "calc("+  height+" + 20px)" : "calc(26px + 20px)" : height ? height : "26px";
    const autoIDtemp = "5645asd2a1d65w46q5e";
    const [modoFloat, setModoFloat] = useState(String(value).includes(",") || String(value).includes("."));


    useEffect(() => {
        if(String(value).includes(",") || String(value).includes(".")){
            if(!modoFloat){
                setModoFloat(true);
            }
        }
       // toast.success(value)
     }, [value])

    useEffect(() => {
       if(modoFloat){
        let obbj = document.getElementById(autoIDtemp);
        if(obbj){
            obbj.focus();
            obbj.setSelectionRange(obbj.value.length, obbj.value.length);
        }
       }
    }, [modoFloat])


    const currencyMask = createNumberMask({
        prefix: '',
        decimalSymbol: ',', 
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalLimit: 3,
        integerLimit: 13,
        allowNegative: false,
        allowLeadingZeroes: false,
    });

    function createClass(mdl){
        let base = "";
        let classe = "";
        //---------------------------------------
        if(mdl == 1){ classe = "bedit-model-1"; }
        else if(mdl == 2){ classe = "bedit-model-2"; }
        else{ classe = "bedit-model-3"; }    
        //---------------------------------------
        return(classe + " " +base)
    }

    return(
        <BMolder modelo={modelo} className={className} width={width} minWidth={minWidth} cor={cor} titulo={titulo} disabled={disabled} height={height ? height : null}>
   
                
                { modoFloat ? 
                    <MaskedInput id={autoIDtemp} name={ name ? name : null }
                        className={createClass(modelo ? modelo : 1)}
                        style={{ backgroundColor: cor ? cor : null, textAlign:"center"}}
                        mask={currencyMask} 
                        value={ floatToReal(value, 3) }
                        disabled={disabled}
                        onChange={(e) => {setValue(realToFloat(e.target.value, 0, 3)) }} 
                        onFocus={(e) => e.target.select() }
                        inputMode='numeric'    
                    /> 
                :             
                    <div className={"number-input-interno"}  >    
                    {comBtn == true && !disabled && <>     
                        <button onClick={ () => { 
                                    if( safeInt(value, min)-1 >= min ){ 
                                        setValue(safeInt(value,min)-1) 
                                    }                                
                              
                                
                            }} >{"-"}</button>
                            </>}
                        <input type="number" name={name ? name : null }
                            className={createClass(modelo ? modelo : 1)+" quantity"}
                            min={min} max={max} defaultValue={def ? def : null}  
                            ref={reef} 
                            placeholder={hint ? hint : null}
                            style={{ backgroundColor: cor ? cor : null, textAlign:"center"}}
                            disabled={disabled}
                            onFocus={(e) =>  (e.target.select(), onEnter ? onEnter() : null)  }
                            onBlur={ onExit ? () => { onExit() } : null  }
                            onKeyDown={ (e) => { if(e.key == ","){ setModoFloat(true); setValue(e.target.value+",") } handleEnter(e, next)} } 
                            onChange={ (e) => { if(String(e.target.value).length <= 0 || safeInt(e.target.value) < min){setValue(min)}else{setValue(e.target.value)}   }} 
                            value={ ( String(value).includes(",") || String(value).includes(".") ) ? value : value ? safeInt(value,min) : min }
                        />
                          {comBtn == true && !disabled && <>   
                        <button onClick={ () => {if(safeInt(value,min)+1 <= max){                           
                                    setValue(safeInt(value,min)+1)                
                        } }} >{"+"}</button>         
                        </>}
                    </div>   
                }
       </BMolder>
    )
}

export const BCods = ({disabled, gtin, setGtin, cod, setCod, getProd=true, next="", focus="", ret=()=>{}, checkNaSaida=false, gerarInterno=false }) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);
    const [ carregando, setCarregando ] = useState(false);
    const [ jaExiste, setJaExiste ] = useState("");

    const [ buscado_gtin, setBuscado_gtin ] = useState("");
    const [ buscado_cod, setBuscado_cod ] = useState("");
    

    function api_checkExists(op=0){
        asyncGet("prodCheckExist", { gtin: gtin, cod: cod }, 
        (e)=>{ 
          //  if(focus.length > 1){
          //      focusComponent(focus);
          //  } 
          if(e == 1){
            setJaExiste("Código de barras já existe.");
          }else if(e == 2){
            setJaExiste("Código Interno já existe.");
          }else if(e == 3){
            setJaExiste("Código de barras e Interno já existem.");
          }

          ret(e);     
          
          if(op == 1){
            focusComponent("BCods_cod");
          }
        },true, setCarregando); 
    }

    return(
        <BSpinDiv display={carregando} style={{display:"flex", gap:"11px", color: jaExiste ? "red" : "black"}}>

            <BEdit disabled={disabled} tipo_numero name="BCods_gtin" next="BCods_cod" titulo="Código de Barras" width={"120px"} 
                value={gtin} setValue={setGtin} buscar={ getProd && !checkNaSaida ?
                    (e) => { 
                        if(gtin.length > 0 && buscado_gtin != gtin){
                            setBuscado_gtin(gtin);
                            ret(0);
                            setJaExiste("");
                            api_checkExists(1);
                        }
                                            
                    } : null }
                    
                    onExit={ !getProd && checkNaSaida ?
                        (e) => { 
                           
                            if(gtin.length > 0 && buscado_gtin != gtin){
                                setBuscado_gtin(gtin);
                                ret(0);
                                setJaExiste("");
                                api_checkExists(1);
                            }                    
                                                
                        } : null }/>     
            <BEdit disabled={disabled} tipo_numero name="BCods_cod" next={next} titulo="Interno" width={"63px"} 
                    value={cod} setValue={setCod} buscar={ getProd && !checkNaSaida ?
                        (e) => { 
                         
                            if(cod.length > 0 && buscado_gtin != gtin){
                                setBuscado_gtin(gtin);
                                ret(0);
                                setJaExiste("");
                                api_checkExists(1);
                            }              
                                                
                        } : null }
                        
                        onExit={ !getProd && checkNaSaida ?
                            (e) => { 
                                if(cod.length > 0 && buscado_cod != cod){
                                    setBuscado_cod(cod);
                                    ret(0);
                                    setJaExiste("");
                                    api_checkExists(1);
                                }                    
                                                    
                            } : null }/> 
     
                {jaExiste.length > 5 && <strong>{jaExiste}</strong> }
        </BSpinDiv>
    )
}

export const BMolder = ({id, children, icon, modelo=1, className, width, minWidth, cor, titulo, multiline, disabled, marginTop, height="37px" }) => {
    function createFundoClass(mdl){
        let base = "";
        let classe = "";
        //---------------------------------------
        if(mdl == 1){ classe = "bedit-fundo-model-1"; }
        else if(mdl == 2){ classe = "bedit-fundo-model-2"; } // all bordas, com pequena margem
        else if(mdl == 3){ classe = "bedit-fundo-model-3"; }
        else{ classe = "bedit-fundo-model-4"; }    
        //---------------------------------------
        return(classe + " " +base)
    }

    let tmp_height = height;

    if(!height && height == null){
        tmp_height = "37px";
    }

    function ghExtr(emu){
        return(emu ? " + 5px " : " + 0px ")
    }

    let isHor = false;
    if(modelo == 4){
        isHor = true;
    }

    const he = isHor ? ("calc("+  tmp_height +ghExtr(multiline) +")") : 
    ( titulo && String(titulo).length > 0 ? "calc("+  tmp_height + ghExtr(multiline) +" + 18px)" : "calc("+  tmp_height +ghExtr(multiline) +")" );


    return(<>
        <div  id={id ? id : null} className={createFundoClass(modelo) + (className ? " "+className : " ")}
                 style={{width: width && width, minWidth: minWidth && minWidth, height: he, marginTop: marginTop ? marginTop : null, 
                 backgroundColor: disabled ? ('rgb(248,248,248)') : (cor ? cor : 'white') }}> 
                 {icon ? <>
                <div style={{display:"flex", flexDirection:"row", height:"100%", width:"100%", paddingRight:"8px"}}>
                    <div style={{display:"flex", flexDirection:"row", height:"auto", alignItems:"center", justifyContent:"center", paddingLeft:"8px", paddingRight:"8px"}}>
                        {icon}
                    </div>
                    <div style={{display:"flex", flexDirection:"column", width:"100%", height:"100%", justifyContent:"center", marginBottom:"0px"}}>
                        { titulo && String(titulo).length > 0 && <div className="bedit-item-title">{titulo}</div>}
                        <div  style={{height:"auto",width:"100%", backgroundColor:cor ? cor : 'white', overflow:"hidden"}}>          
                            {children}
                        </div>
                    </div>
                </div>
                 </>:
                 <>
             
                    { titulo && String(titulo).length > 0 && <div className="bedit-item-title">{titulo}</div>}
                    <div  style={{height:"100%",width:"100%", display:"flex", backgroundColor:cor ? cor : 'white'}}>          
                        {children}
                    </div>
         
                 </>}
             
        </div>
    </>)
}

export const BEdit = (props) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);

    function createClass(mdl){
            let base = "";
            let classe = "";
            //---------------------------------------
            if(mdl == 1){ classe = "bedit-model-1"; }
            else if(mdl == 2){ classe = "bedit-model-2"; }
            else{ classe = "bedit-model-3"; }    
            //---------------------------------------
            return(classe + " " +base)
        }


    return(
            <BMolder id={props.id ? props.id : null} modelo={props.modelo ? props.modelo : 1} className={props.className} height={props.height ? props.height : null} width={props.width} minWidth={props.minWidth} cor={props.cor} titulo={props.titulo} disabled={props.disabled}>
                   { props.multiline == true ?
                    <textarea name={props.name ? props.name : null} className={createClass(props.modelo ? props.modelo : 1)}
                        type={props.tipo_numero || props.tipo_valor ? "number" : "text"} 
                        ref={props.reef} 
                        style={{ backgroundColor: props.cor ? props.cor : null, textAlign: props.align ? props.align : null, 
                            fontWeight: props.bold ? 700 : null,  textTransform: props.toUpper ? "uppercase" : null }}
                        disabled={props.disabled}
                        placeholder={props.hint && props.hint}
                        onFocus={(e) =>  (e.target.select(), props.onEnter ? props.onEnter() : null)  }
                       
                        onKeyDown={ props.buscar && props.value.length > 0 ? null : (e) => handleEnter(e, props.next)}
                        onKeyUp={ 
                            props.buscar ? 
                                (e) => e.key == "Enter" || e.key == "Next" || e.key == "Go" || e.key == "Okay" || e.key == "Ok" ? 
                                        (props.buscar(props.value), handleEnter(e, props.next) ) 
                                    : 
                                       props.onKeyUp ? props.onKeyUp(e.key.toLowerCase()) : null  
                            :   
                                props.onKeyUp ? (e) => props.onKeyUp(e.key.toLowerCase()) : null 
                            } 
                    onChange={(e) => props.setValue && props.setValue( props.tipo_valor ? (e.target.value) : e.target.value)} 
                    value={props.value}/>
                    : props.mask ? 
                    <MaskedInput name={ props.name ? props.name : null }
                    mask={props.mask} 

                    className={createClass(props.modelo ? props.modelo : 1)}
                        type={props.tipo_numero || props.tipo_valor ? "number" : props.tipo_doc ? "tel" : "text"} 
                        ref={props.reef} 
                        style={{ backgroundColor: props.cor ? props.cor : null, textAlign: props.align ? props.align : null, 
                            fontWeight: props.bold ? 700 : null,  textTransform: props.toUpper ? "uppercase" : null }}
                        disabled={props.disabled}
                        placeholder={props.hint && props.hint}
                        onFocus={(e) =>  (e.target.select(), props.onEnter ? props.onEnter() : null)  }
                        onBlur={ props.onExit ? () => { props.onExit() } : null } 
                        onKeyDown={ props.buscar ? null : (e) => handleEnter(e, props.next)}
                        onKeyUp={ 
                            
                            props.buscar ?        
                            (e) => e.key == "Enter" || e.key == "Next" || e.key == "Go" || e.key == "Okay" || e.key == "Ok"? 
                                    (props.buscar(props.value), handleEnter(e, props.next) ) 
                                : 
                                    props.onKeyUp ? props.onKeyUp(e.key.toLowerCase()) : null  
                        :   
                            props.onKeyUp ? (e) => props.onKeyUp(e.key.toLowerCase()) : null 
                            } 
                    onChange={(e) => props.setValue && props.setValue( props.tipo_valor ? (e.target.value) : e.target.value)} 
                    value={props.value}

                    //inputMode='numeric'       
                    />               
                    :
                    <input name={props.name ? props.name : null} className={createClass(props.modelo ? props.modelo : 1)}
                        type={props.tipo_numero || props.tipo_valor ? "number" :props.buscar ? "search" : "text" } 
                        ref={props.reef} 
                        style={{ backgroundColor: props.cor ? props.cor : null, textAlign: props.align ? props.align : null, 
                            fontWeight: props.bold ? 700 : null,  textTransform: props.toUpper ? "uppercase" : null }}
                        disabled={props.disabled}
                        placeholder={props.hint && props.hint}
                        onFocus={(e) =>  (e.target.select(), props.onEnter ? props.onEnter() : null)  }
                        onBlur={ props.onExit ? () => { props.onExit() } : null } 
                        onKeyDown={ props.buscar ? null : (e) => handleEnter(e, props.next)}
                        onKeyUp={ 
                            
                            props.buscar ?        
                            (e) => e.key == "Enter" || e.key == "Next" || e.key == "Go" || e.key == "Okay" || e.key == "Ok"? 
                                    (props.buscar(props.value), handleEnter(e, props.next) ) 
                                : 
                                    props.onKeyUp ? props.onKeyUp(e.key.toLowerCase()) : null  
                        :   
                            props.onKeyUp ? (e) => props.onKeyUp(e.key.toLowerCase()) : null 
                            } 
                    onChange={(e) => props.setValue && props.setValue( props.tipo_valor ? (e.target.value) : e.target.value)} 
                    value={props.value}/>




                    }
                    
                    { props.btn_busca_auto && <>
                        <Tooltip title={"Busca automática ativada"}>
                        <div style={{marginRight:"0px", marginTop:"0px", marginBottom:"2px", padding:"4px", 
                                     width:"65px", height:"100%", display:"flex" ,alignItems:"center"  }}>
                        <div style={{marginRight:"0px", marginTop:"0px", marginBottom:"2px", padding:"4px", 
                                     width:"65px", height:"23px", display:"flex" ,alignItems:"center", justifyContent:"space-around",
                                     backgroundColor:"rgb(231, 231, 231)", borderRadius: "10px"  }}>
                            <p style={{fontSize:"11px", color:"rgb(150, 150, 150)",  }} >auto</p>
                            <VscSearch size={14} style={{ minWidth:"14px" }} color={"rgb(150, 150, 150)"} onClick={ (e) => {  
                            if(props.buscar ) { props.buscar(props.value); handleEnter(e, props.next); }
                            } } />
                        </div></div></Tooltip>
                    </>}
                    { props.buscar && !props.btn_busca_auto && <div style={{height:"100%", display:"flex", alignItems:"center", paddingTop:"2px"}}>
                        <VscSearch size={18} style={{marginRight:"8px", cursor:"pointer", minWidth:"18px"}} onClick={ (e) => {  
                        if(props.buscar ) { props.buscar(props.value); handleEnter(e, props.next); }
                        } } />
                    </div>}
            </BMolder>
    )
}

const dateFormat = 'DD/MM/YYYY';


export const BAppContent = ({children,titulo,sub,submenu,voltar, sem_margem=false }) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);

    const { signIn, loading, setUser, user, menuAberto, setMenuAberto } = useContext(AppContext);

    return(<>
        <div className={'app-box'} style={{height:"100%"}}>
            <Title voltar={voltar} titulo={titulo} name={sub} sub={submenu}>
            
            </Title>
            <div className={`app-content ${ menuAberto ?  `cnt-sibe-aberto` : `cnt-sibe-fechado` } ${ sem_margem ? `app-padding-sem` : `app-padding-com` }`}>
                { children }
            </div>
            </div>
        </>
    )
}

export const BSpin = ({children, display=false, carregando=true, texto="Carregando...", sub="", txBtn, corBtn, onClick}) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);
    return(<>
            {display ? <>
                <div className={'bspin-content'} >
                { children }
                <div className={'bspin-box'} >           
                    <div className={'bspin-center'} >
                        { carregando && <>
                            <LoadingOutlined style={{ fontSize: 38}} spin />
                            <p className="bspin-texto">{texto}</p> 
                            <p>{sub}</p> 
                        </> }
                     
                        
                    </div>           
                </div>
                </div>
            </>:<>
            { children }</>}         
        </>
    )
}


export const BSpinIntern = ({children, item_pai, display=false, carregando=true, texto="Carregando...", sub="",opacidade, txBtn, corBtn, onClick}) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);
    //const  ta = {offsetTop: "10px", offsetLeft: "10px", offsetLeft: "10px", offsetHeight: "10px"}
    const [ ta, setTa ] = useState({offsetTop: "10px", offsetLeft: "10px", offsetLeft: "10px", offsetHeight: "10px"});

    useEffect(()=> {   
        if(item_pai){
            try{
                if(document.getElementById(item_pai) != null){
                    setTa(document.getElementById(item_pai));
                }else{
                    delay(()=> {                    
                        if(document.getElementById(item_pai) != null){
                            setTa(document.getElementById(item_pai));
                            }
                    }, 1500);
                }
                //  delay(()=> { ta = document.getElementById(item_pai); alert(ta)  }, 1000);
            
            }catch{
               // alert(item_pai)
            }
        }

    },[]);
  

    return(<>
            {display ? <>
                
                <div className={'bspin-intern-box'} style={{top:ta.offsetTop, left:ta.offsetLeft, height: ta.offsetHeight, minHeight: ta.offsetHeight, 
                                                            width: ta.offsetWidth, minWidth:ta.offsetWidth, opacity:opacidade ? opacidade : null}} >           
                    <div className={'bspin-intern-center'} style={{height: ta.offsetHeight, minHeight: ta.offsetHeight}}>
                        { carregando && <>
                            <LoadingOutlined style={{ fontSize: parseInt(ta.offsetHeight) <= 80 ? 20 : 38}} spin />
                        </> }
                        { texto.length > 0 && <>
                            <p className="bspin-intern-texto" style={{ fontSize:  parseInt(ta.offsetHeight) <= 80 ? 14 : 20 }}>{texto}</p> 
                            <p>{sub}</p> 
                        </> }
                        
                     
                     
                        
                    </div>           
                </div>
                { children }
            </>:<>
            { children }</>}         
        </>
    )
}

export const BSpinDiv = ({className, style, id, children, display=false, carregando=true, texto="Carregando...", sub="",opacidade, txBtn, corBtn="black", onClick}) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);
    //const  ta = {offsetTop: "10px", offsetLeft: "10px", offsetLeft: "10px", offsetHeight: "10px"}
   
    return(<>
        <div className={ (className ? (className+ " ") : "") + "bspin-div-div"} style={style} id={id}>
            {display ? <>
                
                <div className={'bspin-div-box'} style={{opacity:opacidade ? opacidade : null}} >           
                    <div className={'bspin-div-center'}>
                        <div className={'bspin-div-tx'}>
                            { carregando && <>
                                <LoadingOutlined style={{ fontSize: 24, color:"black", marginRight:"8px"}}  spin />
                            </> }
                            { texto.length > 0 && <><div className={'bspin-div-info'}>
                                <p className="bspin-div-texto" style={{ fontSize: 14 }}>{texto}</p> 
                                { sub && sub.length > 0 && <><div>{sub}</div>  </> }
                                </div> 
                            </> }
                        </div>

                        <div>
                            {txBtn && onClick && <BButton cor={corBtn} texto={txBtn} onClick={()=>{onClick()}}/>}      
                        </div>    
                     
                        
                    </div>    
                    
                </div>
                { children }
            </>:<>
            { children }</>}      
        </div>   
        </>
    )
}

export const BDate = (props) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);
    const [ dformat, setDformat ] = useState('DD/MM/YYYY');
       
    useEffect(() => {
        if(props.tipo)
        {
            if(props.tipo == 'date'){ // 
                setDformat('DD/MM/YYYY');
              }else if(props.tipo == 'month'){ // 
                setDformat('MM/YYYY');
              }else if(props.tipo == 'year'){ //
                setDformat('YYYY');
              }else{ // 
                setDformat('DD/MM/YYYY');
              }
        }

      }, []);

    function createClass(mdl){
            let base = "";
            let classe = "";
            //---------------------------------------
            if(mdl == 1){ classe = "bedit-model-1"; }
            else if(mdl == 2){ classe = "bedit-model-2"; }
            else{ classe = "bedit-model-3"; }    
            //---------------------------------------
            return(classe + " " +base)
        }



    return(
        <BMolder modelo={props.modelo ? props.modelo : 1} className={props.className} width={props.width} minWidth={props.minWidth} cor={props.cor} titulo={props.titulo} disabled={props.disabled}>
                <DatePicker className={createClass(props.modelo ? props.modelo : 1)}
                format={dformat} size="small" picker={props.tipo ? props.tipo : "date"} 
                onChange={(date, dateString) => {props.setValue(safeStr(dateString).split("T",1));} }
                disabled={props.disabled} value={ props.value === moment ? props.value.format(dformat) : moment(props.value, dformat) }
                onFocus={(e) => e.target.select() }
                />    
        </BMolder>
    )
}


export const BDateRanger = ({modelo=1, titulo="", className="", disabled=false, width="auto", minWidth="none", value, setValue = ()=> {}}) => {
    const [data_a, setData_a] = useState(moment(new Date().getDate(),dateFormat).set('date', -1));
    const [data_b, setData_b] = useState(moment(new Date().getDate(),dateFormat).endOf('month'));
    //const { signIn, loading, setUser, user } = useContext(AppContext);
    const [ dformat, setDformat ] = useState('DD/MM/YYYY');
       

    useEffect(() => {
       // setValue([data_a,data_b])
      }, [data_a,data_b]);

    function createClass(mdl){
            let base = "";
            let classe = "";
            //---------------------------------------
            if(mdl == 1){ classe = "bedit-model-1"; }
            else if(mdl == 2){ classe = "bedit-model-2"; }
            else{ classe = "bedit-model-3"; }    
            //---------------------------------------
            return(classe + " " +base)
        }

    function taOK(){
        return false
    }

    const selectionRange = {
        startDate: new Date(data_a),
        endDate: new Date(data_b),
        key: 'selection',
      }

     //  onFocus={(e) => e.target.select() }
    return(
        <BMolder modelo={modelo ? modelo : 1} className={className} width={width} minWidth={minWidth} titulo={titulo} disabled={disabled}>
                <input type="date" value={value.length == 2 ? value[0] : moment(new Date().getDate(),dateFormat)} onChange={()=>{}} />

                <p>até</p>
                <DatePicker className={createClass(modelo ? modelo : 1)} picker={"date"} 
                format={dformat} size="small" onChange={(date, dateString) => {setData_b(safeStr(dateString).split("T",1));} }
                disabled={disabled} value={ value.length == 2 ? dayjs(value[1], dateFormat) : moment(new Date().getDate(),dateFormat) }
                onFocus={(e) => e.target.select() }
                />    
        </BMolder>
    )
}




export const BValor = ({titulo="", className, minWidth, name, next, value, setValue, cor, modelo=1, disabled=false, width, height, retFloat=true, autoP=-1, onExit, onEnter, onKeyUp = ()=>{}}) => {
    /* Obs
       Entrada de valor (VALUE) é FLOAT, e a saída (setValue) por padrão tbm é FLOAT.
    */

        function createClass(mdl){
            let base = "";
            let classe = "";
            //---------------------------------------
            if(mdl == 1){ classe = "bedit-model-1"; }
            else if(mdl == 2){ classe = "bedit-model-2"; }
            else{ classe = "bedit-model-3"; }    
            //---------------------------------------
            return(classe + " " +base)
        }

        const currencyMask = createNumberMask({
            prefix: '',
            decimalSymbol: ',', 
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: '.',
            allowDecimal: true,
            decimalLimit: 2,
            integerLimit: 13,
            allowNegative: false,
            allowLeadingZeroes: false,
        });

    

       function pressP(e){
        if(autoP >= 0 && e.keyCode === 32){ // espaço = 32
            setValue(autoP);
        }
       }

    return(
        <BMolder modelo={modelo} className={className} width={width} minWidth={minWidth} cor={cor} titulo={titulo} disabled={disabled} height={height ? height : null}>
                <MaskedInput name={ name ? name : null }
                    className={createClass(modelo ? modelo : 1)}
                    style={{ backgroundColor: cor ? cor : null}}
                    mask={currencyMask} 
                    value={ floatToReal(value)}
                    disabled={disabled}
                    onKeyDown={ (e) => { pressP(e); handleEnter(e, next)}}
                    onKeyUp={ (e) => { onKeyUp(e) }}
                    onBlur={ onExit ? () => { onExit() } : null } 

                    onChange={(e) => { retFloat ? setValue(realToFloat(e.target.value)) : setValue(e.target.value) }} 
                    onFocus={(e) => (e.target.select(), onEnter ?  onEnter() : null) }
                    inputMode='numeric'
        
                /> 
     
            </BMolder>

    )

}

export const BCombo = ({children, hor_small, titulo, full, small, lado, setValue, value, reef, next, style="", setKey, setAux, width, height="46px"}) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);

 
    const getClassItem = () => { 
            if(full){ 
                return("bedit-row-item-full") 
            }else if(small){
                return("bedit-row-item-small")
            }else if(lado){
                    return("bedit-row-item-lado")
                }else if(hor_small){
                    return("bedit-row-item-hor-small")
            }else{
                return("bedit-row-item")
            }       
        }

    return(

            <div className={ getClassItem() } style={{width: width && width, height:height }}>
                { titulo  && <div className="bedit-item-title">{titulo}</div> }
                <select className='bedit-input' type="text"  ref={reef} style={style ? style  : {}} onKeyDown={ (e) => handleEnter(e, next)} 
                 onChange={(e) => { 
                     if(setKey || setAux){
                        let pr = JSON.parse(e.target.value);        
                        setValue(e.target.value); 
                        setKey && setKey(pr.key); 
                        setAux && setAux(pr.aux);
                     }else{
                        setValue(e.target.value); 
                     }
                   
             
                    }} value={value}>
                 <option>Selecione</option> 
                 {children}
                </select>
            </div>
    )
}
     

export const BCombo2 = ({modelo=1, children, tam="", titulo="", setValue, className, value, reef, next, cor="white", setKey, width, disabled, 
                         minWidth, autoWidth, multiline, marginTop, height, carregando=false}) => {
 

   
    function createClass(mdl){
            let base = "";
            let classe = "";
            //---------------------------------------
            if(mdl == 1){ classe = "bedit-model-1"; }
            else if(mdl == 2){ classe = "bedit-model-2"; }
            else{ classe = "bedit-model-3"; }    
            //---------------------------------------
            return(classe + " " +base)
        }


      
    return(
        <BMolder modelo={modelo} className={className} marginTop={marginTop} combo2="5px" width={width ? width : null} height={height ? height : null}
                minWidth={minWidth} bordaCor={cor} bordaSize={"3px"} titulo={titulo} disabled={disabled}>
                <Select  
                    className={createClass(modelo ? modelo : 1)}
                    loading={carregando} style={{height:"93%", width: '100%', marginTop:"2px" }} type="text" 
                    ref={reef} onKeyDown={ (e) => handleEnter(e, next)} 
                    onChange={(e) => { setValue(e) }} value={value}>
                    {children}
                </Select>

                
            </BMolder>
    )

}
export const BComboOp = ({children, value={}}) => {
    return(
        <Option value={value}>{children}</Option> 
    
    );
}

export const BCirc = ({cor="green", cor_b="", left, top}) => {
    return(<>
        { cor_b ? <>
            <div className="bcirculo_a" style={{backgroundColor: "white", border: cor_b ? "2px solid "+cor_b : "", }}>
            <div className="bcirculo_b" style={{backgroundColor: cor ? cor : "green",}}/>  
        </div> 
        </> : <>
            <div className="bcirculo" style={{backgroundColor:cor ? cor : "green", marginLeft:left ? left : null, marginTop: top ? top : null}}/> 
        </> }
        </>
    );
}

export const BCheck = ({titulo, full, small, small2, small3, setValue, cor, value, reef, next, width="auto", textPos="start"}) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);

 
    const getClassItem = () => { 
            if(full){ 
                return("bedit-row-check-small") 
            }else if(small){
                return("bedit-row-check-small")
            }else if(small2){
                return("bedit-row-check-small")
            }else if(small3){
                return("bedit-row-check-small3")
            }else{
                return("bedit-row-check")
            }       
        }

    return(

            <div className={ getClassItem() } style={{width: width}}>
                {textPos == "start" &&  <div className="bedit-item-title-lateral" style={{color: cor ? cor : "black"}} 
                                             onClick={() => { setValue(!value) } }>{titulo}</div>}
               
                <input type="checkbox" style={{ marginLeft: textPos == "start" ? "5px" :"0px",  marginRight: textPos == "end" ? "5px" :"0px", }} onChange={(e) => setValue(!value)} checked={value} ref={reef} onKeyDown={ (e) => handleEnter(e, next)} 
                className='bedit-check' id="chk_a35235235tivo" name="chk_ativ2352352o" /> 
                {textPos == "end" &&  <div className="bedit-item-title-lateral" style={{color: cor ? cor : "black"}} 
                                            onClick={() => { setValue(!value) } }>{titulo}</div>}
            </div>
    )

}

// [ {  tipo:"2", filtro_value:"1", filtro_col: "id_aux" } ]




export const BTreeItem = ({children, class_n="", id=-1,  titulo, btn_add_tx, returnClick, }) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);
 
    return(
     
        <li  className={class_n}><div className="tree-titulo" onClick={  returnClick(id)  }>{titulo} 
            <div className="tree-btn-edit"><FiMoreHorizontal size={15} /></div></div>
            { children }
        
        </li>

    )

}


export const BEditIcon = ({titulo, setValue, value}) => {
    const [ alterarIcone, setAlterarIcone ] = useState(false);
    const { isMobile } = useContext(AppContext);
    let ta = {offsetTop: "10px", offsetLeft: "10px", offsetLeft: "10px", offsetHeight: "10px"}
    try{
        if(!isObjectEmpty("bediticon-56546010")){
            let itt = document.getElementById("bediticon-56546010");
            if(itt){
                ta = itt;
            }
        }
    }catch{
        //
    }

    return(<>
        <div id={"bediticon-56546010"} className={"bediticon-content"} >
            { safeStr(value).length <= 0 ? <p style={{fontSize:"30px", margin:"0", padding:"0"}}>📦</p>
            : <p style={{fontSize:"30px", margin:"0", padding:"0"}}>{value}</p> }
             
            <BButton texto={"Alterar o ícone"} onClick={ ()=>{ setAlterarIcone(!alterarIcone); } } />
            
        </div>
        { isMobile ? <>
        <div className={"bediticon-picker"} style={{ top:`5px`, left:`5px` }} >
                     { alterarIcone && <EmojiPicker onEmojiClick={(e)=>{ setValue(e.emoji); setAlterarIcone(false); }} /> }        
        </div></>: <>
        <div className={"bediticon-picker"} style={{ top:`calc(${ta.offsetTop} + 120px)`, left:`calc(${ta.offsetLeft} + 350px)` }} >
                     { alterarIcone && <EmojiPicker onEmojiClick={(e)=>{ setValue(e.emoji); setAlterarIcone(false); }} /> }        
        </div></>}



        </>
    )
}

function convertCorToRgb(cor){
    if(cor.length <= 6){
        if(cor == "black"){
            return "rgb(15, 15, 15)";
        }else if(cor == "black"){
            return "rgb(15, 15, 15)";
        }else if(cor == "blue"){
            return "rgb(17, 23, 220)";
        }else if(cor == "green"){
            return "rgb(23, 196, 0)";
        }else if(cor == "orange"){
            return "rgb(235, 106, 0)";
        }else if(cor == "red"){
            return "rgb(236, 0, 0)";
        }else if(cor == "yellow"){
            return "rgb(224, 221, 41)";
        }else if(cor == "gray"){
            return "rgb(104, 104, 104)";
        }else if(cor == "white"){
            return "rgb(255, 255, 255)";
        }else if(cor == "pink"){
            return "rgb(249, 21, 181)";
        }else if(cor == "purple"){
            return "rgba(176, 16, 216, 1)";
        }else if(cor == "grey"){
            return "rgb(81, 81, 81)";
        }
    }
    return cor;
}

export const BButton = ({icon, fundo="rgb(250, 254, 255)", cor="black", sel="blue", padding, name, fontSize="12px",
                        borda=true, bordaCor="rgb(183, 183, 183)", bordaSize=1, texto, novaAba=false, emAlerta=false,
                        height="37px", width="auto", disabled, carregando=false, check=false, 
                        onClick=()=>{}, txTipo, to}) => {

    let bordaSizeTmp = bordaSize;
    if(check){
        bordaSizeTmp += 1;
    }

    //const brd = String(bordaSize)+"px solid "+((disabled || carregando) ? "grey": check ? sel : bordaCor);
    const corr = String(((disabled || carregando) ? "grey": check ? sel : convertCorToRgb(bordaCor) ))
    const cor_fraca = corr.replace("rgb","rgba").replace(")",", 0.4)")
    const brd =  String(bordaSize)+"px solid "+cor_fraca;
    const brd2 =  String(bordaSizeTmp)+"px solid "+corr;
  //  border: 1px solid  rgb(239, 239, 239);
  //  border-bottom: 1px solid rgb(202, 202, 202);


    const idTemp = Math.random();
    //TIPO: hint hide check hide/check
    // Na altura Heigth usar numeros PAREDS sempre 28 , 40 , 20 , 22 , 24
    return(<>
        { (disabled || carregando) ? 
        
            <BSpinDiv item_pai={"div-"+idTemp} style={{ marginLeft: "3px", height: height, width: width, paddingLeft: padding ? padding : null, 
                         paddingRight: padding ? padding : null, }} texto={""}  display={carregando ? true : disabled} carregando={carregando} opacidade={"0.3"}>
            { texto && txTipo == "hint" ?
                <button id={idTemp} className="b-button" onClick={onClick}
                    style={{ fontSize:fontSize , height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                    color:"grey", border: borda? brd : 'none', backgroundColor: fundo }} disabled={true}> {icon && <div className="b-button-icon">{icon}</div>}
                </button> 
            : texto && txTipo == "hide" ?
                    <button id={idTemp} className="b-button b-button-tx-auto-hide" onClick={onClick}x
                        style={{fontSize:fontSize ,  height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                        color:"grey", border: borda? brd : 'none', backgroundColor: fundo }} disabled={true}>{icon && <div className="b-button-icon">{icon}</div>}
                        <div className="txtx" >{texto}</div>      
                    </button>
            
                : texto && txTipo == "hide/check" ?
                    <button id={idTemp} className={`b-button ${ !check && "b-button-tx-auto-hide"  } `} onClick={onClick}
                        style={{fontSize:fontSize ,  height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                        color:"grey", border: borda? brd : 'none', backgroundColor: fundo }} disabled={true}>{icon && <div className="b-button-icon">{icon}</div>}              
                        { check ? <div className="b-button-texto">{texto}</div> : <div className="txtx" >{<div className="b-button-texto">{texto}</div>}</div>    }                                      
                    </button>
                    :
                    <button id={idTemp} className="b-button" onClick={onClick}
                        style={{fontSize:fontSize ,  height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                        color:"grey", border: borda? brd : 'none', backgroundColor: fundo }} disabled={true}>{icon && <div className="b-button-icon">{icon}</div>}
                        { texto ? ( txTipo == "check" ? ( check ? <div className="b-button-texto">{texto}</div> : null ) : (<div className="b-button-texto">{texto}</div>) ) : null }                     
                    </button>
            }
            </BSpinDiv>
            :
            <>
            { texto && txTipo == "hint" ?
            <> 
           
                { to ?  <Tooltip title={texto}>
                    <Link id={idTemp} className="b-button" onClick={onClick} target={novaAba ? "_blank" : null} to={to} name={name ? name : null}
                        style={{fontSize:fontSize ,  height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                        color:cor, borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none', backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>}
                    </Link> 
                </Tooltip> : <Tooltip title={texto}> 
                    <button id={idTemp} className="b-button" onClick={onClick} name={name ? name : null}
                        style={{ fontSize:fontSize, height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                        color: cor, 
                        borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none', 
                         backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>}
                    </button> 
                    </Tooltip>  }              
            </>           
            : texto && txTipo == "hide" ?
                    to ? <>
                        <Link id={idTemp} className="b-button b-button-tx-auto-hide" onClick={onClick} target={novaAba ? "_blank" : null} to={to} name={name ? name : null}
                            style={{fontSize:fontSize ,  height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color:cor, borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none', 
                             backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>}
                            <div className="txtx" >{texto}</div>      
                        </Link>
                    </> : <>
                        <button id={idTemp} className="b-button b-button-tx-auto-hide" onClick={onClick}  name={name ? name : null}
                            style={{ fontSize:fontSize , height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color:cor, borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none', 
                             backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>}
                            <div className="txtx" >{texto}</div>      
                        </button>
                    </> 
                : texto && txTipo == "hide/check" ?
                    to ? <>
                        <Link id={idTemp} className={`b-button ${ !check && "b-button-tx-auto-hide"  } `} onClick={onClick} target="_blank" to={to} name={name ? name : null}
                            style={{fontSize:fontSize ,  height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color:cor, 
                            borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none',  backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>}                 
                            { check ? texto : <div className="txtx" >{texto}</div>    }                                      
                        </Link>
                    </> : <>
                        <button id={idTemp} className={`b-button ${ !check && "b-button-tx-auto-hide"  } `} onClick={onClick} name={name ? name : null}
                            style={{fontSize:fontSize ,  height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color: cor, 
                            borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none'
                            , backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>}                 
                            { check ? texto : <div className="txtx" >{texto}</div>    }                                      
                        </button>
                    </> 
                    :
                    to ? <>
                        <Link id={idTemp} className="b-button" onClick={onClick} target={novaAba ? "_blank" : null} to={to} name={name ? name : null}
                            style={{fontSize:fontSize ,  height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color:cor, border: borda? brd : 'none', backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>}
                            { texto ? ( txTipo == "check" ? ( check ? <div className="b-button-texto">{texto}</div> : null ) : (<div className="b-button-texto">{texto}</div>) ) : null }                     
                        </Link>
                    </> : <> 
                        <button id={idTemp} className={"b-button "+(emAlerta ? "b-button-borda" : "")} onClick={onClick} name={name ? name : null} 
                            style={{fontSize:fontSize , height: height, width: width, paddingLeft: padding ? padding : null, paddingRight: padding ? padding : null,
                            color: cor, 
                            
                            borderTop: borda? brd : 'none', borderLeft: borda? brd : 'none', borderRight: borda? brd : 'none', borderBottom: borda? brd2: 'none', 
                            
                            
                            backgroundColor: fundo }} >{icon && <div className="b-button-icon">{icon}</div>}
                            { texto ? ( txTipo == "check" ? ( check ? <div className="b-button-texto">{texto}</div> : null ) : (<div className="b-button-texto">{texto}</div>) ) : null }                     
                        </button>
                    </>
            }
            </>
        }
        
    </>)

}

function strOrVar(strVar, varVar)
{
let res = strVar;
    if(strVar == "@ID"){
        res = varVar;
    }
return res;
}


export const BTree = ({children, itens, returnClick, 
                        c1_add="", c1_filter_1 = "", c1_filter_1_v = "",  c1_filter_2 = "", c1_filter_2_v = "",  c1_filter_3 = "", c1_filter_3_v = "",
                        c2_add="", c2_filter_1 = "", c2_filter_1_v = "",  c2_filter_2 = "", c2_filter_2_v = "",  c2_filter_3 = "", c2_filter_3_v = "",
                        c3_add="", c3_filter_1 = "", c3_filter_1_v = "",  c3_filter_2 = "", c3_filter_2_v = "",  c3_filter_3 = "", c3_filter_3_v = "",
                        c4_add="", c4_filter_1 = "", c4_filter_1_v = "",  c4_filter_2 = "", c4_filter_2_v = "",  c5_filter_3 = "", c4_filter_3_v = "",
                    }) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);
    return(
            <div className="grupos-lista-fundo">
                <ul className="grupos-lista">
                    {<>
                        { c1_filter_1.length > 0 ? 
                        itens.filter((ite) => (c1_filter_1? ite[c1_filter_1] == c1_filter_1_v : ""=="")
                                            &&(c1_filter_2? ite[c1_filter_2] == c1_filter_2_v : ""=="") 
                                            &&(c1_filter_3? ite[c1_filter_3] == c1_filter_3_v : ""=="")).map((ite) => {return(
                            <BTreeItem titulo={ite.nome} btn_add_tx={c1_add} id={ite.id} returnClick={returnClick()}>
                                { c2_filter_1.length > 0 ? 
                                    itens.filter((ite2) => (c2_filter_1? ite2[c2_filter_1] == strOrVar(c2_filter_1_v, ite.id) : ""=="")
                                                         &&(c2_filter_2? ite2[c2_filter_2] == strOrVar(c2_filter_2_v, ite.id) : ""=="") 
                                                         &&(c2_filter_3? ite2[c2_filter_3] == strOrVar(c2_filter_3_v, ite.id) : ""=="")).map((ite2) => {return(
                                        <BTreeItem class_n="sub" titulo={ite2.nome} btn_add_tx={c2_add} id={ite2.id}>
                                            { c3_filter_1.length > 0 ? 
                                                itens.filter((ite3) => (c3_filter_1? ite3[c3_filter_1] == strOrVar(c3_filter_1_v, ite2.id) : ""=="")
                                                                     &&(c3_filter_2? ite3[c3_filter_2] == strOrVar(c3_filter_2_v, ite2.id) : ""=="") 
                                                                     &&(c3_filter_3? ite3[c3_filter_3] == strOrVar(c3_filter_3_v, ite2.id) : ""=="")).map((ite3) => {return(
                                                    <BTreeItem class_n="sub_2" titulo={ite3.nome} btn_add_tx={c3_add} id={ite3.id}>
                                                        { children }           
                                                    </BTreeItem> 
                                                )})                                   
                                                : <></>
                                            }    
                                            <li className="sub_2 tree_add_green">+ Adicionar {c3_add}</li>              
                                        </BTreeItem> 
                                    )})                                   
                                    : <></>
                                }      
                                <li className="sub tree_add_green">+ Adicionar {c2_add}</li>       
                            </BTreeItem> 
                        )})                                   
                        : <></>
                       
                    }            
                    <li className="tree_add_green">+ Adicionar {c1_add}</li>   
                                                                                                  
                    </>}
                </ul>

            </div>
    )

}

//----------------------------------------------------------------------------------------------------------------------------
 //----------------------------------------------------------------------------------------------------------------------------
 //----------------------------------------------------------------------------------------------------------------------------




export const BIcon = ({icon=1,title="", size="18px", cor="black", onClick = () => {}}) =>{
    //'#08c'
    //() => { setItemSel(e.id); setShowEdit(true);

    const i_1 = <SearchOutlined style={{ fontSize: size, color: cor, margin: "4px",
                cursor: onClick ? 'pointer' : 'default' }} 
                onClick={onClick}/>

                const i_2 = <LikeFilled   style={{ fontSize: size, color: cor, margin: "4px",
                cursor: onClick ? 'pointer' : 'default' }} 
                onClick={onClick}/>

                const i_3 = <LikeOutlined style={{ fontSize: size, color: cor, margin: "4px",
                cursor: onClick ? 'pointer' : 'default' }} 
                onClick={onClick}/>

                const i_4 = <DeleteFilled style={{ fontSize: size, color: cor, margin: "4px",
                cursor: onClick ? 'pointer' : 'default' }} 
                onClick={onClick}/>

                const i_5 = <DeleteOutlined style={{ fontSize: size, color: cor, margin: "4px",
                cursor: onClick ? 'pointer' : 'default' }} 
                onClick={onClick}/>

    return(<>
        <Tooltip title={title}>
            { icon == 1 && i_1 }
            { icon == 2 && i_2 }
            { icon == 3 && i_3 }
            { icon == 4 && i_4 }
            { icon == 5 && i_5 }
        </Tooltip>
    </>)
}


export const BMenuContext = ({children, className="", click=null, visivel=false }) => {
    //const { signIn, loading, setUser, user } = useContext(AppContext);

    const [anchorPoint, setAnchorPoint] = useState({ x: "0px", y: "0px" });
    const [show, setShow] = useState(false);

    let xPos ="";
    let yPos ="";

      document.addEventListener("contextmenu",
        useCallback(
          (event) => {
            event.preventDefault();
          
                xPos =(event.pageX -15) + "px";
                yPos =(event.pageY -15) + "px";
                setAnchorPoint({ x: xPos, y: yPos });
           
          },
          [setAnchorPoint]
        )
      );


    return(
        <>
     { visivel ?
        <div  className={"btec-menu-context "+className} style={{ top: anchorPoint.y, left: anchorPoint.x }}  onClick={ () => { click && click() }}>
          <ul className="btec-menu-context-list">
            { children }    
          </ul>
        </div>
        :
        <></>
    }
    </>
    )

}

export const BMenuContextItem = ({children, className="", click=null }) => {

    return(
     
        <li className={"btec-menu-context-item "+className}> 
            <button className="btec-menu-context-item-btn" onClick={ () => { click && click() }}>      
            { children } 
            </button>      
        </li>

    )

}

export const BDisplayGrid = ({children, height="", cols=8, style}) => {
    return(
        <div className={"btec-display-grid"} style={{...style, height:height.length >= 2 ? height : "auto", gridTemplateColumns: "repeat("+cols+", 82px)"}}> 
            {children}
        </div>
    )
}

export const BDisplayValor = ({titulo = "", valor = "0,00", cor="balck", tag=true }) => {
    const estilo = {
        backgroundColor: cor,
    }
    return(<>
        { titulo.length > 12 ? <Tooltip title={titulo}> 
        <div className={"btec-display-valor-box"} > 
                {tag ? <> <div className={"btec-display-valor-tag"} style={estilo}></div></> : <></>}
                <div className={"btec-display-valor-content"} > 
                              
                    <div className={"btec-display-valor-titulo"} > 
                            {"# "+titulo}
                    </div>
                    <div className={"btec-display-valor-valor"} > 
                            {valor}
                    </div>
                 </div>

        </div>   
        </Tooltip>
        :        
        <div className={"btec-display-valor-box"} > 
        {tag ? <> <div className={"btec-display-valor-tag"} style={estilo}></div></> : <></>}
        <div className={"btec-display-valor-content"} > 
                     
           <div className={"btec-display-valor-titulo"} > 
                   {titulo}
           </div>
           <div className={"btec-display-valor-valor"} > 
                   {valor}
           </div>
        </div>
        </div>
        }
    </>)

}

export const BFiltroBox = ({filtros=[], resFiltros= () => {} }) => {
    const [ selFiltros, setSelFiltros ] = useState([]);
    const [ selectTemp, setSelectTemp ] = useState([]);
    useEffect(() => {
       // alert(JSON.stringify(selFiltros));
        resFiltros(selFiltros);    
    }, [selFiltros]);

    const estilo = {
     //   backgroundColor: cor,
    }

    function sepTitu(tx=""){
        // 0 = titulo, 1 = valor
        if(String(tx).length > 4){
            if(String(tx).includes('#|#')){
                let lista_tmp = String(tx).split('#|#');
                return([lista_tmp[0], lista_tmp[1]])
            }else{
                return([tx,tx])
            }
        }else{
            return([tx,tx])
        }

    }

    return(
      <div className="box-filtros">
        <p style={{marginBottom:"5px"}}>Filtrar por</p>  
        
        <BCombo2 width={"100%"} setValue={(e) => { 
            if(!isObjectEmpty(e)){ 
                setSelectTemp(JSON.parse(e));           
            }else{
                setSelectTemp(null);
            }
         }}
         >      
            {filtros.map(e => { return(<BComboOp value={ JSON.stringify(e) }>{e.titulo}</BComboOp>) })}
        </BCombo2>

         { !isObjectEmpty(selectTemp) && 
        <BFiltroItem tipo={selectTemp.tipo} titulo={'Informe o valor do filtro'} label={""} txTipo={selectTemp.txTipo} def={selectTemp.def} apply={(tp, vl) => { 
                    if(String(vl).length > 0){                     
                        if(String(vl).includes(';')){
                            let lista_tmp = String(vl).split(";");
                            let rees = selFiltros;                          
                            lista_tmp.map(aa => { if(String(aa).length > 0){ let tt =  sepTitu(String(aa)); return(                                          
                                rees = [...rees, { tipo: tp, valor: tt[0], value: tp+"="+tt[1] }] 
                            ) } });
                            setSelFiltros(rees)  
                        }else{
                            let tt =  sepTitu(String(vl));
                            setSelFiltros([...selFiltros, { tipo: tp, valor: tt[0], value: tp+"="+tt[1] }]);   
                        }
                    }               
                    }} /> }

        
        {selFiltros.length > 0 && <>
            <div style={{height:"20px",marginTop:"12px", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <p style={{margin:0, color:"rgb(8, 108, 202)"}}>Filtros ativos</p>   <strong style={{cursor:"pointer"}} onClick={()=>{setSelFiltros([])}}>limpar todos</strong>    
            </div> 
            <div className="box-filtros-aplicados">
                
                { 
                //selFiltros.map((item) => {return(<Tag closable onClose={ (p) => { setSelFiltros(selFiltros.filter( e => e.value != item.value));
                //                                                             } }>{item.valor}</Tag>)}) 
                    
                selFiltros.map((item) => {return(<>
                    <div className="box-filtros-tag"><p>{item.valor}</p><div className="tag-x" onClick={()=>{
                    return(setSelFiltros(selFiltros.filter( e => e.value != item.value)))        
                    }} >X</div></div>
                </>)})
            
            }

            </div>
        </>}
      </div>
    )
}

export const BFiltroItem= ({tipo="", titulo ="", label="", txTipo="", def="", value, apply = () => {},  }) => {
    const [ prodDep, setProdDep ] = useState([]);//itens do combo
    const [ prodGrupos, setProdGrupos] = useState([]);//itens do combo
    const [ prodSubGrupos, setProdSubGrupos] = useState([]);//itens do combo

    const [ comboDep, setComboDep ] = useState("");//texto selecionado combo
    const [ comboGrupo, setComboGrupo ] = useState("");//texto selecionado combo
    const [ comboSubGrupo, setComboSubGrupo ] = useState("");//texto selecionado combo

    function get_Id(itemNome){
        let res = -1;
        if(itemNome.length > 0){
           // alert(JSON.stringify( prodDep.filter(p => p.nome == itemNome)));      
            try{
                res = prodDep.filter(p => p.nome == itemNome)[0].id;
            }catch{
                res = -1;
            }
        }     
        return res; 
    }    

    function getNome(itemID){
        let res = "";
        if(itemID >= 0){
          //  alert(JSON.stringify( prodDep.filter(p => p.id == itemID)));      
            try{
                res = prodDep.filter(p => p.id == itemID)[0].nome;
            }catch{
                res = "";
            }
        }     
        return res; 
    }   

    useEffect(() => {
     //   asyncGet("gruposProdGetAll", { }, 
    //    (e)=>{ 
    //       setProdDep(e.grupos);
     //   }, false);


    }, []);  

    useEffect(() => {
     
        //  alert( comboDep +" "+  getId(comboDep))

      let depID = comboDep;
      let gruID =  comboGrupo;

          if (depID >= 0){
              setProdGrupos(prodDep.filter(p => p.tipo === 2 && p.aux == depID));
          }else{
              setProdGrupos([]); 
          }
          if (gruID >= 0){
              setProdSubGrupos(prodDep.filter(p => p.tipo === 3 && p.aux ==  gruID));   
          }else{
              setProdSubGrupos([]);
          } 

    }, [comboDep, comboGrupo]);  

    const estilo = {
       // backgroundColor: cor,
    }
 
    const [ vlr, setVlr ] = useState(def);

    return(
        <div className="box-filtros-lista-item-box" style={{ height : txTipo == "prod_grupo" ? "170px" : txTipo == "prod_sub_grupo" ? "230px" : "65px"}}>
            <div className="titulo">{ titulo }</div>
            <div className="content" style={{display: txTipo == "prod_grupo" || txTipo == "prod_sub_grupo" ? "flex" : null  , 
                                             flexDirection: txTipo == "prod_grupo" || txTipo == "prod_sub_grupo" ? "column" : null, }}>
                { (txTipo == "texto" || txTipo == "tx" || txTipo == "text" || txTipo == "cod") ? 
                    <> <BEdit width={180} titulo={label} setValue={setVlr} value={vlr} /> </> 
                    : (txTipo == "qtd" || txTipo == "int") ?
                        <> <BNum width={180} titulo={label} setValue={setVlr} value={safeInt(vlr,0)} min={-99} max={9999} /> </> 
                        : (txTipo == "combo" || txTipo == "list") ? 
                            <> <BCombo width={180} titulo={label} setValue={setVlr} value={vlr} /></> 
                            : (txTipo == "valor" || txTipo == "float") ? 
                            <> <BValor width={180} titulo={label} setValue={setVlr} value={vlr} /> </> 
                            : (txTipo == "prod_dep") ? 
                            <> 
                            <BCombo2 width={"180px"} titulo={"Departamento"} setValue={setVlr} value={vlr}>
                                    {prodDep.filter(p => p.tipo === 1).map( (item) => { 
                                    return( <BComboOp value={item.id}>{item.nome}</BComboOp>)})}
                            </BCombo2>
                            </> 
                            : (txTipo == "prod_grupo") ? 
                            <> 
                            <BCombo2 width={"180px"} titulo={"Departamento"} setValue={setComboDep} value={comboDep}>
                                    {prodDep.filter(p => p.tipo === 1).map( (item) => { 
                                    return( <BComboOp value={item.id}>{item.nome}</BComboOp>)})}
                            </BCombo2>
                        
                            <BCombo2 titulo="Grupo" width={"180px"} setValue={setVlr} value={vlr}>
                                    {prodGrupos.filter(p => p.tipo === 2).map( (item) => { 
                                    return( <BComboOp value={item.id}>{item.nome}</BComboOp>)})}
                            </BCombo2>
                            </> 
                            : (txTipo == "prod_sub_grupo") ? 
                            <> 
                            <BCombo2 width={"180px"} titulo={"Departamento"} setValue={setComboDep} value={comboDep}>
                                    {prodDep.filter(p => p.tipo === 1).map( (item) => { 
                                    return( <BComboOp value={item.id} >{item.nome}</BComboOp>)})}
                            </BCombo2>
                            
                            <BCombo2 titulo="Grupo" width={"180px"} setValue={setComboGrupo} value={comboGrupo}>
                                    {prodGrupos.filter(p => p.tipo === 2).map( (item) => { 
                                    return( <BComboOp value={item.id} >{item.nome}</BComboOp>)})}
                            </BCombo2>

                            <BCombo2 titulo="Sub Grupo" width={"180px"} setValue={setVlr} value={vlr}>
                                    {prodSubGrupos.filter(p => p.tipo === 3).map( (item) => { 
                                    return( <BComboOp value={item.id} >{item.nome}</BComboOp>)})} 
                            </BCombo2>
                            </> 
                            : (txTipo == "cadastro" || txTipo == "cad") && 
                            <> <SelCliente titulo='Fornecedor' width={"92%"} value={vlr} setValue={setVlr}/> </>}
                

                <Button type="primary" shape="circle" icon={<PlusOutlined />} size="small" onClick={ () => {
                    if(txTipo == "qtd" || txTipo == "int"){
                        if(tipo.length == 3 && vlr > -99){ let tmp = vlr; setVlr(def); apply(tipo,tmp);  }
                    }else if(txTipo == "prod_dep" || txTipo == "prod_grupo" || txTipo == "prod_sub_grupo"){ 
                        
                        if(tipo.length == 3 && vlr > 0){ apply(tipo, getNome(vlr)+"#|#"+String(vlr)); setVlr(def); }
                    }else if(txTipo == "cadastro"){ 
                        if(!isObjectEmpty(vlr)){
                            apply(tipo,vlr.nome+"#|#"+String(vlr.id)); setVlr(def); 
                        }
                    }else{
                         if(tipo.length == 3 && vlr.length > 0){ apply(tipo,vlr); setVlr(def); }
                    }
                }} />
            </div>              
        </div>
    )
}

export const BSelecter = ({children, carregando=false, autoFoco=false }) => {
    return(
        <div className={`selecter_content ${ autoFoco && 'selecter_borda' } `} >
            <Spin style={{'height':'100%'}} spinning={carregando} tip="Carregando...">
                {children}
            </Spin>
        </div>
    )
}

export const BSelecterBtn = ({titulo="", id=-1, icon=null, onClick=() => {}, selected=-1, ret=null}) => {



    return(
        <div className={`selecter_btn selecter_bottom ${ selected == id && 'selecter_selected' } `} onClick={ () => onClick(ret ? ret : id)}>
            <div className="selecter_titulo">
                 {titulo}
                 {icon && <Button shape="circle" icon={icon ? icon : null} size="large" /> }
            </div>
        </div>  
    )
}
    




  