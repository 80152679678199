import './Produto.scss'
import React, {useEffect, useState,useContext} from 'react';
import { AppContext } from '../../context';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import avatar from '../../assets/avatar.png';
import Modal from '../../components/Modal/Modal';
import { BNum, BValor, BEdit, BCombo2 ,BComboOp,BCirc, BCheck, BButton } from '../../components/BeiTec';
import { safeInt, safeStr } from '../../services/utils';
import { SearchOutlined, LikeOutlined  } from '@ant-design/icons';
import { Table, Input, Button, Transfer , Select, Space, Row, Col, Spin  } from 'antd';
import SeletorCad from '../../components/Seletor/Seletor';
import prod_c from '../../assets/prod_c.png';
import { MdArrowBackIosNew, MdAttachMoney } from 'react-icons/md';
import { BsCartPlus } from 'react-icons/bs';


const Produto = ({setValue = () => {}, value}) => {
 
    const [carregando, setCarregando] = useState(false);

    const [qtd, setQtd] = useState(1);
    
    useEffect( () => {
       
      }, []);

    return(
        <div className='prod_fundo'>
            
         
            <div className='prod_box'>
                
                <div className='prod_capa_box'>
                    <p className='prod_link_voltar' onClick={()=>{ setValue("") }}><MdArrowBackIosNew style={{ marginRight: "5px" }} size={19} />Voltar aos produtos</p>
                    <div className='prod_capa'>
                        <img src={prod_c} onClick={ () => {  }} ></img>  
                    </div>
                    <div className='prod_aviso'>
                        Produto em estoque ❤️
                    </div>
                    <div className='prod_valores'>
                        <div style={{fontSize:"20px",}}>❤️🍕🎁</div>
                        <div style={{}}>
                            <div style={{textAlign:"end",fontSize:"15px", fontWeight:"600", textDecoration: "line-through", }}>29,90</div>
                            <div style={{fontSize:"20px", fontWeight:"800", }}>69,90</div>
                        </div>
                    </div>
                    <div className='prod_btns'>
                        <BNum  width={"150px"} setValue={setQtd} value={qtd} max={12} min={0}/>
                        <BButton icon={<MdAttachMoney size={21}  color={"green"}/>} fontSize={"14px"} texto={"Comprar Agora"} onClick={()=>{ setValue("") }} width={"100%"} bordaCor={"green"} bordaSize={2} emAlerta/>
                        </div>   <div className='prod_btns'>
                        <BButton icon={<BsCartPlus size={22} color={"green"}/>} fontSize={"15px"} texto={"Incluir no Carrinho"} onClick={()=>{ setValue("") }} width={"100%"} bordaCor={"green"} bordaSize={2}/>
                    </div>
                </div>
                <div className='prod_infos_box'>               
                    <div className='prod_titulo'>
                        Retratos do Cárcere – 1a temporada
                    </div>
                    <div className='prod_sinop'>
                    Alimentado por um modelo penitenciário falido, pela omissão ou incompetência das autoridades e pela ousadia dos que nada têm a perder, o crime organizado vicejou no Rio Grande do Sul nas últimas décadas, levando pânico à população e transformando os estabelecimentos prisionais em sucursais do inferno e base de operações da Falange Gaúcha. A guerra pelo controle das ações criminosas, em especial do tráfico de drogas e dos assaltos a bancos e carros-fortes, traduziu-se em derramamento de sangue dentro e fora dos presídios, motins, sequestros, perseguições cinematográficas, traições e vinganças. E, apesar de sucessivas promessas, o Presídio Central, palco principal desses acontecimentos, continua de pé, com sua superlotação e absoluta falta de condições.
                    Foi para contar e mapear tal realidade que o jornalista Renato Dornelles reuniu neste livro-reportagem farto material, fruto de pesquisas, de observações e de coberturas jornalísticas. Antes de se tornar livro, o tema já servira como conteúdo de uma série de reportagens publicada no Jornal Diário Gaúcho, que rendeu ao autor o Premio ARI de Jornalismo em 2007.
                    </div>
                    <div className='prod_infos'>
                        <div className='prod_infos_item'>Autor: Renato Dornelles</div>
                        <div className='prod_infos_item'>Páginas:  144</div>
                        <div className='prod_infos_item'>Edição: Elizário Goulart Rocha</div>
                        <div className='prod_infos_item'>Revisão: Henrique Erni Gräwer</div>
                        <div className='prod_infos_item'>Capa/ Ilustrações: Robinson Estrásulas</div>
                        <div className='prod_infos_item'>Editoração: Gustavo Demarchi</div>
                    </div>
                    <div className='prod_cep_box'>    
                        <div className='prod_cep_btns'>           
                            <div className='prod_cep_tx'>
                                Calcule o frete
                            </div>
                            <BEdit titulo="CEP" modelo={4} width={"130px"}/>
                            <BButton texto={"Calcular"} onClick={()=>{ }} />
                        </div>
                        <div className='prod_cep_lista'>   
                            <p>Grátis, retira na loja X</p>
                            <p>18,20 - Correios (5 dias aprox)</p>
                            <p>23,40 - Sedex (3 dias aprox)</p>
                            
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    )

}

export default Produto;