import { toast } from 'react-toastify';
import api, { apiFile } from './api';

import { isMobile } from 'react-device-detect';


export function beep(_string)
{                   

}     

export function apenasNumeros(_string)
{                                          
    if(_string.match(/^[0-9]+$/) != null){
        return true;
    } else {
        return false;
    }
}     

export function retornaApenasNumeros(_string)
{               
    let a = String(_string).replace(/\D/g, '');                           
    return a;
}   

export const focusComponent = (name) => {
        if(!isMobile){
            try{
                const el = document.getElementsByName(name);
                if(el.length === 1){
                    el[0].focus();
                }
            }catch{
                alert("Objeto não encontrado!");
            }
        }
  };

export const selectComponent = (name) => {
        if(!isMobile){
            try{
                const el = document.getElementsByName(name);
                if(el.length === 1){
                    el[0].select();
                }
            }catch{
                alert("Objeto não encontrado!");
            }
        }
  };

  

export const delay = ( func = () => {} , tempo=350) => {
    setTimeout(func, tempo);
};

export const userPerm = ( perm="", api=false, callback=()=>{}) => {
    
    let res = false;
    if(String(perm).length > 1){
        if(api){
            asyncGet("userPerm", { perm:safeStr(perm) }, (e) => { 
                callback(e);            
            }, false);
            
        }else{
            let tmp_perm = "";
            try{
                tmp_perm = String(localStorage.getItem("user_perm"));
            }catch{
                tmp_perm = ""
            }
            tmp_perm = safeStr(tmp_perm);
            if(tmp_perm.length > 2){
                let list = tmp_perm.split(";")
                if(list.indexOf(perm) >= 0){
                    res = true;
                }
            }
        }    
    }
    return res;
};



export const focusComponentPorID = (id) => {
    try{
        const el = document.getElementById(id);
        if(el){
            el.focus();
        }
    }catch{
        alert("Objeto não encontrado!");
    }
};


export const selectComponentPorID = (id) => {
    try{
        const el = document.getElementById(id);
        if(el){
            el.select();
        }
    }catch{
        alert("Objeto não encontrado!");
    }
};

export const handleEnter = (e, next = null) => {
    if (e.key == "Enter" || e.key == "Next" || e.key == "Go" || e.key == "Okay" || e.key == "Ok") {
        
        if(next){
            focusComponent(next);
        }
    }
  };

export function getLocalStorage(tipo="", isArray: boolean = false)
{      
    let obj = {};   
    if (isArray){
        obj = [];
    }                                 
    if(tipo.length > 1){
        if(tipo == "ch_users"){
            let a = localStorage.getItem('ch_users');
            if(a && a != null && String(a).length > 10){
                obj = JSON.parse(a);   
            }else{
               // alert(JSON.stringify(obj));
                asyncGet("getUsers", { }, (e) => { 
      
                    localStorage.setItem('ch_users', JSON.stringify(e.users));
                    obj = e.users;          
                }, false);
            }
        }
        if(tipo == "aaa"){
            
        }
    }
    return obj;
}     

export function safeStr(_string, def="")
{         

    let ret = def;                                    
    if(_string != null || _string != undefined || _string != NaN){
        if( String(_string).length > 0 ){
          
            if( String(_string).toUpperCase() == "NONE" || 
                String(_string).toUpperCase() == "NULL" || 
                String(_string).toLowerCase() == 'undefined'){
                 
            }else{
                ret = String(_string);    
            }
   
        }
      //  alert(ret)
    }
    return ret;
}     

export function safeInt(_string, def=0)
{         
    let ret = def;   
                               
    if(isNaN(_string) || _string != null || _string != NaN || _string != undefined ){
            let str = String(_string); 
            if(str.split(".").length - 1 >= 1){
                ret = parseInt(str.split(".")[0]) || def;
            }else if (str.split(",").length - 1 >= 1 ){
                ret = parseInt(str.split(",")[0]) || def;
            }else{
              
                ret = parseInt(_string) || def;
            }
    }else{
        if(_string == '' || String(_string).length <= 0){
            ret =  def;   
        }else{
            ret =  parseInt(_string);   
        }      
    }
    return ret;
}   

export function safeFloat(_string, def=0.0)
{         
    let ret = def;                                  
    if(isNaN(_string) || _string != null || _string != NaN || _string != undefined ){       
        ret = parseFloat(_string) || def;
    }else{
        if(_string == '' || String(_string).length <= 0){
            ret =  def;   
        }else{
            ret =  parseFloat(_string);   
        }      
    }
    return ret;
}  


export function isObjectEmpty(obj) { // Verifica se o obj está vazio, True para vazio, false para cheio.
    if(Object.keys(obj).length === 0){ // 9788000121451
        return true;
    }else{
        let tt = String(JSON.stringify(obj));
       
        if(tt.length >= 2 && tt.length <= 8){
           
            tt = String(tt).replaceAll(" ", "");
            
            if(tt == "{}" || tt == "[]" || tt == "{[]}" || tt == "[{}]" || tt == "{{}}" || tt == "[[]]" || tt == "[{{}}}"){
                //alert(">"+tt)
                return true;
            }else{
                return false;
            }
        }else{
            if(String(obj).length > 2){
                return false;
            }else{
                return true;
            }            
        }               
    }  
  }

export function safeBol(_boolean, def=false)
{         
    let ret = def;                                 
    if(_boolean != null){
        if( _boolean === "1" ){        
            ret = true;    
        }else{
            if( _boolean === 1 ){        
                ret = true;    
            }else{
                if( _boolean === true ){        
                    ret = true;    
                }
            }
        }
   
    }
    return ret;
}    

export function getInGlobal(_string="")
{
    let res = "";
    if(_string.length > 0){
       let obj = JSON.parse(localStorage.getItem("cfg_global"));
       if(obj && obj[_string]){
        res = String(obj[_string]);
       }else{
           res =  String(_string.replaceAll("_"," ")).toUpperCase();
       }
    }
    
    return res;
}


export function converteMovProdTipo(tipo){
    let cor = "yellow";
    let tipoTx = "";
    let icon = "0";
    if(String(tipo).length == 3){
        if (tipo[0] == "1") cor = "green";   
        if (tipo[0] == "2") cor = "red"; 
        if (tipo[0] == "3") cor = "blue";
        
        icon = String(tipo)[0]; // 1 up / 2 down / 3 igual
    
        if (tipo == "100") tipoTx = "Entrada Manual";   
        if (tipo == "101") tipoTx = "Alim Compra"; 
        if (tipo == "102") tipoTx = "Alim Consig";  
        if (tipo == "103") tipoTx = "Entrada de Ajuste";  
        if (tipo == "104") tipoTx = "Entrada de estorno";  
        if (tipo == "105") tipoTx = "Entrada Outras";  

        if (tipo == "200") tipoTx = "Venda Caixa";
        if (tipo == "201") tipoTx = "Venda fora do Caixa";
        if (tipo == "202") tipoTx = "Saída Devolução de Compra";
        if (tipo == "203") tipoTx = "Saída Devolução de Consig";
        if (tipo == "204") tipoTx = "Saída de Ajuste";
        if (tipo == "205") tipoTx = "Saída de Estorno";
        if (tipo == "206") tipoTx = "Outras Saídas";

        if (tipo == "300") tipoTx = "Ajuste";
 
    }
    return { 'tx':tipoTx, 'cor':cor, 'icon': icon }
}


export function converteMovProdConsigTipo(mov_tipo){
    let cor = "yellow";
    let tipoTx = "";
    let icon = "0";
    let tipo = String(mov_tipo);
    if(String(tipo).length == 2){
        if (tipo == "10" || tipo == "11" || tipo == "20" || tipo == "21"){
            cor = "green";   
            icon = "1"; // 1 up / 2 down / 3 igual
        } 
        if (tipo == "12" || tipo == "13" || tipo == "14" || tipo == "22" || tipo == "23" || tipo == "24") {
            cor = "red";   
            icon = "2"; // 1 up / 2 down / 3 igual
        } 
  
    
        if (tipo == "10") tipoTx = "Entrada";   
        if (tipo == "11") tipoTx = "Entrada sem Estoque"; 
        if (tipo == "12") tipoTx = "Acerto";  
        if (tipo == "13") tipoTx = "Devolução";  
        if (tipo == "14") tipoTx = "Devolução sem Estoque";   

        if (tipo == "20") tipoTx = "Envio";   
        if (tipo == "21") tipoTx = "Envio sem Estoque"; 
        if (tipo == "22") tipoTx = "Acerto";  
        if (tipo == "23") tipoTx = "Devolução";  
        if (tipo == "24") tipoTx = "Devolução sem Estoque";  

    }
    // toast.success(tipo)
    return { 'tx':tipoTx, 'cor':cor, 'icon': icon }
}


export function updStateObject(v_object=[], setList, call)
{
    let clone = v_object;  
    // (fMaip) => fMaip.map( e =>     
    clone.map( call );
   //clone.map( e => { if(e.key == key){ e={...e, qtd:25}; }});
   setList(clone);
   return clone;
}


export function updStateJson(v_json=[], setList, call)
{
   let clone = JSON.parse(JSON.stringify(v_json));  
   // (fMaip) => fMaip.map( e =>     
   clone.map( call );
   //clone.map( e => { if(e.key == key){ e={...e, qtd:25}; }});
   setList(clone);
   return clone;
}

export function updStateJsonOne(v_json={}, setList, col="", value="")
{
   let clone = JSON.parse(JSON.stringify(v_json));  
   // (fMaip) => fMaip.map( e =>     
   clone[col] = value;
   //clone.map( e => { if(e.key == key){ e={...e, qtd:25}; }});
   setList(clone);
   return clone;
}

export async function asyncGet(func="", objs={}, sucess=()=>{}, silent=false, loand=()=>{}, err=()=>{}){
    // asyncGet("func", {objs}, ()=>{sucesso});
    if(loand){ loand(true); }
    try {           
      const resp = await api.post(`/`+func, objs, {headers: {'Content-Type': 'application/json', Authorization: 'Bearer '+localStorage.getItem("loja_tk")}});    
      if(resp.data.err == 0){  
      
        if(loand){ loand(false); }                    
        sucess(resp.data.res);
      }else{
        if(loand){ loand(false); }
        if(!silent){
          toast.warn(resp.data.err_tx);
        }
        err(resp.data);
      }
    }catch(e){
        if(loand){ loand(false); }
        err('EXCEPT');
      if(!silent){
        toast.error('Erro. ao carregar . '+e);
      }
    } 
   // if(loand){ loand(false); }
  }



export async function asyncSendFile(func="", objs=null, sucess=()=>{}, silent=false, loand=()=>{}, err=()=>{}){
    // asyncGet("func", {objs}, ()=>{sucesso});
    if(loand){ loand(true); }
    try {           
      const resp = await apiFile.post(`/`+func, objs, {headers: {'Content-Type': 'multipart/form-data', Authorization: 'Bearer '+localStorage.getItem("loja_tk")} });    
      if(resp){  
   
        if(loand){ loand(false); }                    
        sucess(resp.data.res);
      }else{
        if(loand){ loand(false); }
        if(!silent){
          toast.warn(resp.data.err_tx);
        }
        err(resp.data);
      }
    }catch(e){
      if(loand){ loand(false); }
      if(!silent){
        toast.error('Erro. ao carregar . '+e);
      }
    } 
    
  }

  export async function asyncGetFile(func="", objs=null, sucess=()=>{}, silent=false, loand=()=>{}, err=()=>{}){
    // asyncGet("func", {objs}, ()=>{sucesso});
    if(loand){ loand(true); }
    try {           
      const resp = await apiFile.post(`/`+func, objs, {responseType: 'blob', headers: {'Content-Type': 'multipart/form-data', Authorization: 'Bearer '+localStorage.getItem("loja_tk")} });    
      if(resp.data){  
        
        if(loand){ loand(false); }                    
        sucess(resp.data);
      }else{
        if(!silent){
          toast.warn("Erro ao baixar arquivo.");
        }
        err(resp.data);
      }
    }catch(e){
      if(!silent){
        toast.error('Erro. ao carregar . '+e);
      }
    } 
    if(loand){ loand(false); }
  }

export function bolToInt(_boolean, def=0)
{         
    let ret = def;     
                          
    if(_boolean != null){
        if( _boolean === true ){        
            ret = 1;    
        }
   
    }
    return ret;
}    

export function safeQtd(_string, def=0)
{         
    let ret = def;   
    let decDigs = 0;
    let str = safeStr(_string, def=String(def));     
    if(str != null && str.length > 0){  
            if( str.includes(".") && str.includes(",") ){
       
                if( str.indexOf(".") > str.indexOf(",") ){ // 00,00.00
                    str = String(str).replace(',', '')
                }
                if( str.indexOf(".") < str.indexOf(",") ){ // 00.00,00
                    str = String(str).replace('.', '').replace(',', '.')
                }             
            }else{
               // alert("B")
                if( str.includes(".") ) {
                    // OK
                
                } else if( str.includes(",") ) {   
                
                    str = String(str).replace(',', '.')
                } else {
                    // OK
                    
                } 
            }
            if( str.includes(".") ) {
                decDigs = 3;
                
            }
            if(decDigs >= 1){
                ret = parseFloat(parseFloat(str).toFixed(decDigs)); 
            }else{
                ret = parseFloat(str); 
            }            
    }
    return ret;
}   


export function realToFloat(_real:string, def:number=0.0, decDigs:number=2)
{         

    let ret:number = def;     
    let str = safeStr(_real, def="");   
                        
    if(str != null && str.length > 0){  
        /* 
        12.500,95
        25.159,954
           520,50
           520,514

        1000.00
        1000.512
       2,100.21
       */
      /*
        let virgulas = str.split(",").length-1; 
        let pontos = str.split(".").length-1; 

        if(virgulas == 1 && pontos == 0){
            ret = parseFloat(parseFloat(String(str).replace(',', '.')).toFixed(2));   
        }else if(virgulas == 0 && pontos == 1){
            ret = parseFloat(parseFloat(String(str)).toFixed(2)); 
        }else if(virgulas == 1 && pontos >= 2){
            ret = parseFloat(parseFloat( String(str).replace('.', '').replace(',', '.')).toFixed(2));  
        }else if(virgulas >= 2 && pontos == 1){
            ret = parseFloat(parseFloat( String(str).replace(',', '')).toFixed(2));  
        }else if(virgulas == 1 && pontos == 1){
            ret = parseFloat(parseFloat( String(str).replace('.', '').replace(',', '.')).toFixed(2)); 
        }else if(virgulas == 0 && pontos == 0){
            ret = parseFloat(parseFloat( String(str)).toFixed(2)); 
        }
       */
        if(String(str).includes(",")){
            ret = parseFloat(parseFloat( String(str).replace('.', '').replace(',', '.')).toFixed(decDigs)); 
        }else{
            ret = parseFloat(parseFloat(String(str)).toFixed(decDigs)); 
        }  
      // ret = parseFloat(parseFloat( String(str)).toFixed(2));  
    }
   // alert("RR>"+ret)
    return ret;
}    

export function arredondarDec(_real:number, def:number=2)
{       //  alert(parseFloat(parseFloat(_real).toFixed(2)));
    if (_real < 0.0) { _real = 0.0 } 
    let ret:number = parseFloat(parseFloat(_real).toFixed(2));                       
    return ret;
}    

export function floatToReal(_real:number, decDigs=2,  dec:string="", decPos:string="", deb:boolean=false, )
{       
    //_real = arredondarDec(_real);
    if(deb){alert("A:"+_real); }  
    
    let ret:string = _real.toLocaleString('pt-br', {maximumFractionDigits: decDigs, minimumFractionDigits: decDigs});
    if(ret.length <= 0){
        if(decDigs === 1){
            ret = "0,0"
        }else if(decDigs === 2){
            ret = "0,00"
        }else if(decDigs === 3){
            ret = "0,000"
        }else if(decDigs === 4){
            ret = "0,0000"
        }else{
            ret = "0"
        }       
    }
    if(deb){alert("R:"+ret); }      
    
    if(String(dec).length > 0){
        if(String(decPos).length > 0){
            return String(dec)+ret+String(decPos);
        }else{
            return ret;
        }  
    }else{
        if(String(decPos).length > 0){
            return ret+String(decPos);
        }else{
            return ret;
        } 
    }  
}    


export function findStrInList(lista, busca_col, busca_valor, res_col)
{
  
    let res = "";
    let oob = lista.find((e) => e[busca_col] == busca_valor);
    if(oob){
        res = safeStr(oob[res_col]);

    }
    return res;
}


export function findIntInList(lista, busca_col, busca_valor, res_col, def=-1)
{
    let res = def;
    let oob = lista.find((e) => e[busca_col] == busca_valor);
    if(oob){
        res = parseInt(oob[res_col]);

    }
    return res;
}

export function findInList(lista, busca_col, busca_valor, res_col, def={})
{
    let res = def;
    let oob = lista.find((e) => e[busca_col] == busca_valor);
    if(oob){
        if(res_col == "$"){
            res = oob;
        }else{
            res = oob[res_col];
        }
    }
    return res;
}